import React from "react";
import { Link, StaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Seznam from "../../assets/img/seznam.png";
import Forbes from "../../assets/img/forbes.png";
import iDnes from "../../assets/img/idnes.png";
import PozitivniZpravy from "../../assets/img/pozitivniZpravy.png";
import E15 from "../../assets/img/e15trans.png";
import StartupJobs from "../../assets/img/startupJobs.png";
import CzechCrunch from "../../assets/img/ccLogo.svg";
import HN from "../../assets/img/hn_logo.svg";
import Denik from "../../assets/img/denik.png";
import Aktualne from "../../assets/img/aktualne.png";
import CallButtonV2 from "./CallButtonV2";
import LinkButtonV2 from "./LinkButtonV2";
import { useMediaQuery } from "react-responsive";


const IntroInner = styled.div`
  .wrap {
    max-width: 1150px;
    width: calc(100% - 100px);
    margin: 70px auto;
    display: flex;
    align-self: flex-start;
    justify-content: space-between;

    @media (max-width: 899px) {
      display: block;
      width: calc(100% - 50px);
      max-width: 700px;
    }

    @media (max-width: 600px) {
      margin: 50px auto;
    }

    h2 {
      font-family: NewKansas;
      font-size: 36px;
      color: #2c4668;
      line-height: 50px;

      @media (max-width: 899px) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 25px;
      }

      @media (max-width: 430px) {
        font-size: 28px;
        line-height: 35px;
      }
    }

    .left {
      max-width: calc(400px);
      text-align: left;

      @media (max-width: 1040px) {
        max-width: calc(350px);
      }

      @media (max-width: 960px) {
        max-width: calc(300px);
      }

      @media (max-width: 899px) {
        max-width: 100%;
      }

      .teamFoto {
        border-radius: 10px;
        margin-bottom: 25px;
        display: block;

        @media (max-width: 1040px) {
          width: 350px !important;
        }

        @media (max-width: 960px) {
          width: 300px !important;
          height: 200px !important;
        }

        @media (max-width: 899px) {
          width: 100% !important;
          display: block;
          margin: 0 auto;
          max-width: 450px;
          height: 310px !important;
          margin-bottom: 25px;
        }

        @media (max-width: 650px) {
          height: 280px !important;
        }

        @media (max-width: 400px) {
          height: 220px !important;
        }
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 18px;
        color: rgba(0,0,0,0.53);
        line-height: 28px;
        display: block;
      }

      ul {
        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          margin-top: 15px;

          @media (max-width: 899px) {
            margin-right: 15px;
            margin-top: 10px;
          }

          &:hover {
            img {
              filter: grayscale(100%) brightness(0%) opacity(60%);
            }
          }

          img {
            filter: grayscale(100%) brightness(0%) opacity(35%);
            max-height: 20px;
            width: auto;

            @media (max-width: 380px) {
              max-height: 18px;
            }
          }

          .jobs {
            height: 16px;

            @media (max-width: 380px) {
              max-height: 14px;
            }
          }

          .seznam {
            max-height: 24px;

            @media (max-width: 380px) {
              max-height: 22px;
            }
          }

          .aktualne {
            max-height: 35px;

            @media (max-width: 380px) {
              max-height: 30px;
            }
          }
        }
      }
    }

    .right {
      max-width: calc(575px + 100px);
      width: calc(100% - 400px - 60px);

      @media (max-width: 1040px) {
        width: calc(100% - 350px - 40px);
      }

      @media (max-width: 960px) {
        width: calc(100% - 300px - 40px);
      }

      @media (max-width: 899px) {
        width: calc(100%);
      }

      p {
        font-family: Visuelt-Regular;
        font-size: 18px;
        color: #394B5C;
        line-height: 28px;
        margin-top: 30px;
        margin-bottom: 40px;

        b {
          font-weight: inherit;
          font-family: Visuelt-Bold;
        }

        a {
          color: #394B5C;
        }

        @media (max-width: 430px) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .buttons {
        display: flex;

        @media (max-width: 1160px) {
          display: block;
        }

        @media (max-width: 899px) {
          display: flex;
        }
        
        @media (max-width: 650px) {
          display: block;
        }
        
        div {
          margin-right: 20px;

          @media (max-width: 1160px) {
            margin-right: 0;
            margin-top: 15px;
          }

          @media (max-width: 899px) {
            margin-right: 20px;
            margin-top: 0;
          }

          @media (max-width: 650px) {
            margin-right: 0;
            margin-top: 15px;
          }
        }
      }
    }

  }

  .eko {
    background: #d7ebcb;
    border-radius: 5px;
    padding: 8px 15px;
    margin-top: 20px;
    display: block;
    width: fit-content;
    font-family: Visuelt-Regular;
    font-size: 15px;
    color: #122805;
    line-height: 24px;

    b {
      font-family: Visuelt-Bold;
      font-weight: normal;
    }
  }
`;


const Intro = ({introH2, introText, link, baby, eko}) => {

  const isLess899 = useMediaQuery({
    query: '(max-width: 899px)'
  });

  return (
    <IntroInner>
      <div className="wrap">
        {isLess899 && <h2>{introH2}</h2>}
        <div className="left">
          <StaticQuery
            query = {graphql`
              query {
                careTeam: file(relativePath: { eq: "careTeam.png" }) {
                  childImageSharp {
                    fixed(width: 400, quality: 100) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            `}
            render={data => (<Img className="teamFoto" alt={"Tým pohřební služby Goodbye"} fixed={data.careTeam.childImageSharp.fixed} />)}
          />
          <span>Můžete nás znát z:</span>
          <ul>

            <li>
              <a target="_blank" href="https://www.e15.cz/byznys/obchod-a-sluzby/digitalizovane-pohrebnictvi-start-up-goodbye-chce-revoluci-v-oboru-1379081">
                <img src={E15} alt="Digitalizované pohřebnictví. Start-up Goodbye chce revoluci v oboru"/>
              </a>
            </li>
            
            <li>
              <a target="_blank" href="https://forbes.cz/pohrebnictvi-funguje-uz-150-let-stejne-ted-to-chce-zmenit-cesky-startup/">
                <img src={Forbes} alt="Pohřebnictví funguje už 150 let stejně. Teď to chce změnit český startup"/>
              </a>
            </li>

            <li>
              <a target="_blank" href="https://www.idnes.cz/zpravy/domaci/data-internet-uzivatele-spolecnost-nabidka-sluzby-pohreb-umrti-online-zavet-notari.A220413_151632_domaci_lre">
                <img src={iDnes} alt="Digitální pozůstalost. Uspořádat je třeba účty na sítích, potíž je s kryptoměnami"/>
              </a>
            </li>

            <li>
              <a target="_blank" href="https://zpravy.aktualne.cz/domaci/ekologicke-pohrbivani/r~a321b27ed6a911eca9eeac1f6b220ee8/">
                <img className="aktualne" src={Aktualne} alt="Pohřeb bez rakve, do lesa i na louku. Za pár let běžné, věří expert ministerstva"/>
              </a>
            </li>

            <li>
              <a target="_blank" href="https://podcasty.hn.cz/c1-67010510-co-nas-ceka-po-smrti-i-silne-konzervativni-obor-jako-pohrebnictvi-ma-sve-trendy">
                <img className="pozitivni" src={HN} alt="Co nás čeká po smrti? I silně konzervativní obor jako pohřebnictví má své trendy"/>
              </a>
            </li>
            
            <li>
              <a target="_blank" href="https://cc.cz/misto-rakve-treba-smutecni-strom-ceske-startupy-nabidnou-ekologicke-urny-ktere-se-rozlozi/">
                <img className="jobs" src={CzechCrunch} alt="Místo rakve třeba smuteční strom. České startupy nabídnou ekologické urny, které se rozloží"/>
              </a>
            </li>

            <li>
              <a target="_blank" href="https://www.seznamzpravy.cz/clanek/ani-smrt-nas-nerozdeli-stale-vice-lidi-chce-mit-data-v-digitalnim-trezoru-172228">
                <img className="seznam" src={Seznam} alt="Ani smrt nás nerozdělí. Stále více lidí chce mít data v digitálním trezoru"/>
              </a>
            </li>

            <li>
              <a target="_blank" href="https://brnensky.denik.cz/zpravy_region/pohrby-v-brne-vedou-kremace-s-obradem-pritomnost-kneze-chce-jen-17-lidi-20220425.html">
                <img src={Denik} alt="Pohřby v Brně: vedou kremace s obřadem. Přítomnost kněze chce jen 17% lidí"/>
              </a>
            </li>

          </ul>

          {eko &&
            <div className="eko">
              Kompenzujeme <b>100% emisí uhlíku</b> po každé kremaci 🌿
            </div>
          }

        </div>
        <div className="right">

        
          {!isLess899 && <h2>{introH2}</h2>}

          
          <p>{introText}</p>
          <div className="buttons">
            <LinkButtonV2 to={link ? link : "/nabidka/dotaznik/"} arrow text="Získejte nabídku zdarma" />
            <CallButtonV2 />
          </div>

          
        </div>

      </div>

      
    </IntroInner>
  );
}

export default Intro;


